import { Box } from 'components/Box';
import { Flex } from 'components/Flex';
import {
    GridSection,
    GridSectionLeft,
    GridSectionRight
} from 'components/GridSection';
import { Heading } from 'components/Heading';
import Img from 'gatsby-image';
import React from 'react';
import { Button } from 'src/components/Button';
import { Paragraph } from 'src/components/Paragraph';
import { Section } from 'src/components/Section';
import { useTheme } from 'src/components/ThemeProvider';
import { Video } from 'src/components/Video';

export const StatBox = ({ title, subtitle, kind }) => {
    return (
        <Box
            textAlign={{
                _: 'left',
                lg: 'center',
            }}
        >
            <Paragraph color="boldText" fontWeight="bold" fontSize="30px">
                {title}
            </Paragraph>
            <Paragraph color="boldText" fontWeight="bold">
                {subtitle}
            </Paragraph>
            <Paragraph color="faintText">{kind}</Paragraph>
        </Box>
    );
};

export const ClientHighlight = ({
    image,
    video,
    videoPoster,
    videoId,
    children,
    logo,
    title,
    description,
    link,
    linkText,
    flipped = true,
    mediaProps = {},
    contentProps = {},
    gridSectionProps = {},
    alt,
    ...props
}: any) => {
    const { colors } = useTheme();
    return (
        <Section wrap vertical="lg" bg="secondaryBackground" {...props}>
            <GridSection
                alignItems={{
                    _: 'flex-start',
                    md: 'center',
                }}
                {...(flipped
                    ? {
                          direction: {
                              _: 'column-reverse',
                              md: 'row-reverse',
                          },
                      }
                    : {
                          direction: { _: 'column-reverse', md: 'row' },
                      })}
                borderTop={`1px solid ${colors.border}`}
                pt={['40px', 'spacing-xl', '80px']}
                {...gridSectionProps}
            >
                <GridSectionLeft
                    hasMedia
                    px={0}
                    maxWidth={{ _: '100%', md: '50%', lg: '66%' }}
                    {...(flipped
                        ? {
                              pl: { _: 0, md: 20, lg: 32 },
                          }
                        : {
                              pr: { _: 0, md: 20, lg: 32 },
                          })}
                    {...mediaProps}
                >
                    <Flex
                        width="100%"
                        rounded="radius"
                        {...(flipped
                            ? {
                                  direction: {
                                      _: 'column-reverse',
                                      lg: 'row-reverse',
                                  },
                              }
                            : {
                                  direction: { _: 'column-reverse', lg: 'row' },
                              })}
                    >
                        {children && (
                            <Flex
                                d={{
                                    _: 'none',
                                    lg: 'flex',
                                }}
                                // pl="spacing-xl"
                                align="center"
                                h="100%"
                            >
                                {children}
                            </Flex>
                        )}
                        <Box
                            flex={1}
                            minW={[275, 350]}
                            boxShadow="0 5px 11px 0 rgba(0,0,0,0.15)"
                        >
                            {image && (
                                <Img
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    fluid={image}
                                    alt={alt}
                                />
                            )}

                            {video && (
                                <Video
                                    video={video}
                                    videoId={videoId}
                                    videoPoster={videoPoster}
                                    autoPlay
                                />
                            )}
                        </Box>
                    </Flex>
                </GridSectionLeft>

                <GridSectionRight {...contentProps}>
                    <Flex direction="column">
                        {logo && (
                            <Box h="60px" maxH="60px" mb="spacing">
                                <Img
                                    fixed={logo}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    imgStyle={{
                                        width: 'auto',
                                        height: '100%',
                                        maxWidth: '200px',
                                        objectFit: 'contain',
                                    }}
                                />
                            </Box>
                        )}
                        {title && (
                            <Heading kind="h5" mt={0} mb="spacing">
                                {title}
                            </Heading>
                        )}

                        {description && (
                            <Paragraph fontSize="body" color="bodyText">
                                {description}
                            </Paragraph>
                        )}

                        {link && (
                            <Button
                                href={link}
                                kind="primary"
                                mt="spacing-lg"
                                maxWidth="200px"
                            >
                                {linkText}
                            </Button>
                        )}

                        {children && (
                            <Box
                                d={{
                                    _: 'block',
                                    lg: 'none',
                                }}
                                pb={['spacing-lg', null, 0]}
                                pt={['spacing-lg', null, 'spacing-xl']}
                            >
                                {children}
                            </Box>
                        )}
                    </Flex>
                </GridSectionRight>
            </GridSection>
        </Section>
    );
};
